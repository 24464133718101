import React, { useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { QRCode } from 'react-qrcode-logo';
import logo from '../../../Common/Images/Logo.jpeg'; // Replace with the path to your logo

const QRCodeGenerator = () => {
  const qrRef = useRef();

  const downloadQR = () => {
    const canvas = qrRef.current.querySelector('canvas');
    const url = canvas.toDataURL('image/png');
    const a = document.createElement('a');
    a.href = url;
    a.download = 'shubham_travels_qr.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const qrCodeUrl = 'https://travelshubhankar.pythonanywhere.com/media/Shubhankar%20Travel.apk';

  return (
    <div className="qr-code-container" style={{ textAlign: 'center' }}>
      <div ref={qrRef} style={{ display: 'inline-block' }}>
        <QRCode
          value={qrCodeUrl}
          logoImage={logo}
          size={256}
          logoWidth={64}
          logoHeight={64}
        />
      </div>
      <button onClick={downloadQR} style={{ marginTop: '20px' }}>
        Download QR Code
      </button>
    </div>
  );
};

export default QRCodeGenerator;
