import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import "./Bookings.css";
import { Alert } from 'bootstrap';

const BookingDetails = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('');
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    fetchBookings();
    fetchDrivers();
  }, []);

  const fetchBookings = async () => {
    try {
      const response = await axios.get('https://travelshubhankar.pythonanywhere.com/api/bookings/');
      setBookings(response.data);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  const fetchDrivers = async () => {
    try {
      const response = await axios.get('https://travelshubhankar.pythonanywhere.com/api/drivers/');
      setDrivers(response.data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const handleSubmitStatus = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('new_status', selectedStatus);
      await fetch(`https://travelshubhankar.pythonanywhere.com/api/change-status/${selectedBooking.bookingId}/`, {
        method: 'POST',
        body: formData
      });
      alert('Booking status updated successfully');
      fetchBookings();
    } catch (error) {
      console.error('Error updating booking status:', error);
    }
  };

  const handleSubmitDriver = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('selected_driver', selectedDriver);
      await fetch(`https://travelshubhankar.pythonanywhere.com/api/assign-driver/${selectedBooking.bookingId}/${selectedDriver}/`, {
        method: 'POST',
        body: formData
      });
      alert('Driver assigned successfully');
      fetchBookings();
    } catch (error) {
      console.error('Error assigning driver:', error);
    }
  };

  const handleBookingSelect = (booking) => {
    setSelectedBooking(booking);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(bookings);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Bookings");
    XLSX.writeFile(wb, "bookings.xlsx");
  };

  return (
    <>
      <div className="booking-details-container">
        <div>
          <button onClick={exportToExcel} className="export-button">Export to Excel</button>
          <table className="booking-table">
            <caption className='p-4 font-bold text-xl'>Bookings</caption>
            <thead>
              <tr>
                <th>From Address</th>
                <th>To Address</th>
                <th>Date</th>
                <th>Time</th>
                <th>Phone</th>
                <th>Name</th>
                <th>Action</th>
                <th>Booking ID</th>
                <th>Driver ID</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking) => {
              const driver = drivers.find(driver => driver.DriverId == booking.driverId);
              console.log("drivers",driver,booking.driverId)
                return (
                  <tr key={booking.bookingId} onClick={() => handleBookingSelect(booking)}>
                    <td>{booking.fromaddress}</td>
                    <td>{booking.toaddress}</td>
                    <td>{booking.date}</td>
                    <td>{booking.time}</td>
                    <td>{booking.phone}</td>
                    <td>{booking.name}</td>
                    <td>{booking.action}</td>
                    <td>{booking.bookingId}</td>
                    <td>{booking.driverId!="False" ? driver?.DriverName : "Not assigned"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {selectedBooking && (
        <div className="booking-form border flex flex-col">
          <h3 className='flex w-full items-center p-4 justify-start border-b-2'>Selected Booking: {selectedBooking.name}</h3>
          <div className='flex flex-row p-2 flex-wrap gap-4 justify-between items-center'>
            <form onSubmit={handleSubmitStatus} className='!mb-0 p-4 flex flex-row min-w-[200px] items-center gap-4 flex-wrap justify-center'>
              <label htmlFor="status" className='min-w-fit'>Change Status:</label>
              <select
                id="status"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                className='h-[45px] px-4 w-[200px]'
              >
                {/* <option value="active">Active</option> */}
                <option value="pending">Pending</option>
                <option value="unActive">Inactive</option>
                <option value="Complete">Complete</option>
              </select>
              <button type="submit" className='h-[45px] min-w-fit !px-4'>Update Status</button>
            </form>
            <form onSubmit={handleSubmitDriver} className='!mb-0 p-4 flex flex-row min-w-[200px] items-center gap-4 flex-wrap justify-center'>
              <label htmlFor="driver" className='min-w-fit'>Assign Driver:</label>
              <select id="driver" value={selectedDriver} onChange={(e) => setSelectedDriver(e.target.value)} className='h-[45px] px-4 w-[200px]'>
                <option value="">Select Driver</option>
                {drivers.map((driver) => (
                  <option key={driver.DriverId} value={driver.DriverId}>{driver.DriverName}</option>
                ))}
              </select>
              <button type="submit" className='h-[45px] min-w-fit !px-4'>Assign Driver</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingDetails;
