import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import './TrackingOrder.css';
import axios from 'axios';
const TrackingMap = ({ startCoordinates, endCoordinates,distancew }) => {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [distance, setDistance] = useState(null);

  useEffect(()=>{
    console.log("distance value",distancew)
  },[distancew])
  useEffect(() => {

    if (startCoordinates && endCoordinates) {
      initializeMap(startCoordinates, endCoordinates);
    calculateDistance(startCoordinates, endCoordinates);
    }
  }, [startCoordinates, endCoordinates]);

  const initializeMap = (startCoordinates, endCoordinates) => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYW51ajEyM3F3IiwiYSI6ImNsbnVzNHRzdDBnZWcybG4xYnk2cjdnZTcifQ.fuPVp8gaW0pmIGwg-A6m4g';

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [startCoordinates.lng, startCoordinates.lat],
      zoom: 12,
    });

    const directions = new MapboxDirections({
      accessToken: mapboxgl.accessToken,
      unit: 'metric',
      profile: 'mapbox/cycling',
    });

    map.addControl(directions, 'top-left');

    setMap(map);

    map.on('load', () => {
      new mapboxgl.Marker({ color: 'blue' })
        .setLngLat([startCoordinates.lng, startCoordinates.lat])
        .addTo(map);

      new mapboxgl.Marker({ color: 'red' })
        .setLngLat([endCoordinates.lng, endCoordinates.lat])
        .addTo(map);

      directions.setOrigin([startCoordinates.lng, startCoordinates.lat]);
      directions.setDestination([endCoordinates.lng, endCoordinates.lat]);
    });

    directions.on('route', (event) => {
      if (event.route && event.route.length > 0) {
        const route = event.route[0];
        // setDistance(route.distance);
      }
    });
  };

  // const [distance, setDistance] = useState(null);
  const calculateDistance = async (startCoordinates, endCoordinates) => {

    const { lat: startLat, lng: startLng } = startCoordinates;
    const { lat: endLat, lng: endLng } = endCoordinates;
    const origins = `${startLat},${startLng}`;
    const destinations = `${endLat},${endLng}`;

    try {
      const response = await fetch(
        `https://travelshubhankar.pythonanywhere.com/api/get-distance/?origins=${origins}&destinations=${destinations}`,
        {
          headers: {
            'accept': 'application/json',
            'user-agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36'
          }
        }
      );
      const data = await response.json();
      setDistance(data);
      const distance = data.rows[0].elements[0].distance.text;
      setDistance(distance);
    } catch (error) {
      console.error('Error fetching distance:', error);
    }

   
    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=${startLat},${startLng}&destinations=${endLat},${endLng}&key=AIzaSyCP266W0_rbXWYld5T0YHE7NshSL8mc9-g`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      if (data.rows[0].elements[0].status === 'OK') {
        const distance = data.rows[0].elements[0].distance.text;
        setDistance(distance);
        console.log('Calculated distance:', distance); // Log calculated distance
      } else {
        console.log('Distance calculation failed');
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="map-container">
      <div ref={mapContainerRef} className="map" />
      {distance!=null && (
        <p className="distance">
          Distance: {distance} 
        </p>
      )}
    </div>
  );
};

export default TrackingMap;
