import React from 'react';
import './HomePageSection.css'; // Your custom CSS file for styling

const HomePageSection = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <h1>AMAZING APP BEST FOR BUSINESS</h1>
        <p>Let you track everything in your life with a simple way</p>
        <div className="store-buttons">
  {/* <img
    src="https://getsby.com/wp-content/uploads/2023/06/google-play-badge.png"
    alt="Get it on Google Play"
    className="play-badge"
  /> */}
  <a
    href="https://travelshubhankar.pythonanywhere.com/media/Shubhankar%20Travel.apk"
    className="download-button"
    target="_blank"
    rel="noopener noreferrer"
  >
    Download Now
  </a>
</div>

      </div>
      <div className="banner-image">
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjuQYElORAUxpUrEFkC5c60KVAFvThphLkMTG2TCscdovLyo5BP4OMBcN-CaifXQIzKOs&usqp=CAU" alt="App on Phone" />
      </div>
    </div>
  );
};

export default HomePageSection;
