import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css'; // Import the CSS file for styling
import './gallery.css'; // Import your custom CSS file

const images = [
  {
    original: 'https://static.javatpoint.com/tourist-places/images/tourist-places-of-madhya-pradesh13.png',
    thumbnail: 'https://static.javatpoint.com/tourist-places/images/tourist-places-of-madhya-pradesh13.png',
    placeName: 'Maheshwer', // Place name for the first image
  },
  {
    original: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/03/Sas-Bahu-Temple.jpg',
    thumbnail: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/03/Sas-Bahu-Temple.jpg',
    placeName: 'Indore', // Place name for the second image
  },
  {
    original: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/02/Rajwada-Palace.jpg:cf-webp:w-848:h-551',
    thumbnail: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/02/Rajwada-Palace.jpg:cf-webp:w-848:h-551',
    placeName: 'Rajwada, Indore', // Place name for the third image
  },
  {
    original: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/04/Upper-Vaitarna-Lake.jpg:cf-webp:w-848:h-551',
    thumbnail: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/04/Upper-Vaitarna-Lake.jpg:cf-webp:w-848:h-551',
    placeName: 'Upper Lake, Bhopal', // Place name for the third image
  },
  {
    original: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/04/Lotus-Lake.jpg:cf-webp:w-848:h-551',
    thumbnail: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/04/Lotus-Lake.jpg:cf-webp:w-848:h-551',
    placeName: 'Lotus Lake, Indore', // Place name for the third image
  },
  {
    original: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/03/Rock-Shelters-of-Bhimbetka.jpg:cf-webp:w-848:h-551',
    thumbnail: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/03/Rock-Shelters-of-Bhimbetka.jpg:cf-webp:w-848:h-551',
    placeName: 'Bhimbetka Caves, Bhopal', // Place name for the third image
  },
  {
    original: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/03/Bhojpur-Temple.jpg:cf-webp:w-848:h-551',
    thumbnail: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/03/Bhojpur-Temple.jpg:cf-webp:w-848:h-551',
    placeName: 'Bhojpur Shiv Temple, Bhopal', // Place name for the third image
  },
  {
    original: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/03/Raisen-Fort.jpg:cf-webp:w-848:h-551',
    thumbnail: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/03/Raisen-Fort.jpg:cf-webp:w-848:h-551',
    placeName: 'Raisen Fort, Raisen, Bhopal', // Place name for the third image
  },
  {
    original: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/07/Pandavleni-Caves.jpg:cf-webp:w-848:h-551',
    thumbnail: 'https://www.fabhotels.com/blog/wp-content/uploads/2019/07/Pandavleni-Caves.jpg:cf-webp:w-848:h-551',
    placeName: 'Pandav Caves, Pachmarhi', // Place name for the third image
  },
];

const MyGallery = () => {
  // Customize the styles for the gallery
  const galleryStyles = {
    width: '80%',
    margin: '0 auto', // Center the gallery horizontally
    marginBottom: '20px', // Add some margin at the bottom
  };

  return (
    <div style={galleryStyles}>
      <ImageGallery
        items={images}
        lazyLoad={true} // Enable lazy loading for better performance
        showThumbnails={true} // Show thumbnail navigation
        showBullets={true} // Show bullet navigation
        slideDuration={400} // Set slide transition duration
        slideInterval={5000} // Set slide interval
        renderItem={(item) => (
          <div className="image-gallery-image">
            <img src={item.original} alt={item.originalAlt} />
            <div className="place-name">{item.placeName}</div>
          </div>
        )}
      />
    </div>
  );
};

export default MyGallery;
