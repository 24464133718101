import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import './AddFund.css';

const AddFundData = () => {
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState({
    DriverName: '',
    DriverAddress: '',
    VehicleModel: '',
    VehicleNumber: '',
    SeatCapacity: '',
    TotalAmount: '',
    AmountPerKm: '',
    SellAmount: '',
    bookingId: ''
  });

  useEffect(() => {
    fetchDrivers();
  }, []);

  const handleDriverChange = (e) => {
    setDriver({
      ...driver,
      [e.target.name]: e.target.value
    });
  };

  const fetchDrivers = async () => {
    try {
      const response = await axios.get('https://travelshubhankar.pythonanywhere.com/api/drivers/');
      setDrivers(response.data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const handleDriverSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://travelshubhankar.pythonanywhere.com/api/drivers/', driver);
      console.log('Driver added successfully');
      fetchDrivers(); // Refresh drivers list after adding
    } catch (error) {
      console.error('Error adding driver:', error);
    }
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(drivers);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Drivers");
    XLSX.writeFile(wb, "drivers.xlsx");
  };

  return (
    <div className="flex flex-col gap-4">
      <form onSubmit={handleDriverSubmit} className='p-2 border rounded-md'>
        <h2 className="mt-4 mb-4">Add New Driver</h2>
        <div className="flex flex-row flex-wrap justify-center gap-1">
          <div className="w-full min-w-[200px] max-w-[250px]">
            <input type="text" className="form-control border rounded-sm" placeholder="Driver Name" name="DriverName" value={driver.DriverName} onChange={handleDriverChange} required />
          </div>
          <div className="w-full min-w-[200px] max-w-[250px]">
            <input type="text" className="form-control border rounded-sm" placeholder="Phone Number" name="DriverAddress" value={driver.DriverAddress} onChange={handleDriverChange} required />
          </div>
          <div className="w-full min-w-[200px] max-w-[250px]">
            <input type="text" className="form-control border rounded-sm" placeholder="Vehicle Model" name="VehicleModel" value={driver.VehicleModel} onChange={handleDriverChange} required />
          </div>
          <div className="w-full min-w-[200px] max-w-[250px]">
            <input type="text" className="form-control border rounded-sm" placeholder="Vehicle Number" name="VehicleNumber" value={driver.VehicleNumber} onChange={handleDriverChange} required />
          </div>
          <div className="w-full min-w-[200px] max-w-[250px]">
            <input type="number" className="form-control border rounded-sm" placeholder="Seat Capacity" name="SeatCapacity" value={driver.SeatCapacity} onChange={handleDriverChange} required />
          </div>
          <div className="w-full min-w-[200px] max-w-[250px]">
            <input type="number" className="form-control border rounded-sm" placeholder="Total Amount" name="TotalAmount" value={driver.TotalAmount} onChange={handleDriverChange} required />
          </div>
          <div className="w-full min-w-[200px] max-w-[250px]">
            <input type="number" className="form-control border rounded-sm" placeholder="Amount Per Km" name="AmountPerKm" value={driver.AmountPerKm} onChange={handleDriverChange} />
          </div>
          <div className="w-full min-w-[200px] max-w-[250px]">
            <input type="number" className="form-control border rounded-sm" placeholder="Sell Amount" name="SellAmount" value={driver.SellAmount} onChange={handleDriverChange} required />
          </div>
          <div className="w-full min-w-[200px] max-w-[250px]">
            <input type="text" className="form-control border rounded-sm" placeholder="Booking ID" name="bookingId" value={driver.bookingId} onChange={handleDriverChange} required />
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-primary w-[250px] bg-blue-500 hover:bg-blue-700 text-white">Add Driver</button>
          </div>
        </div>
      </form>

      <div className="table-responsive">
        <button onClick={exportToExcel} className="export-button">Export to Excel</button>
        <table className="table table-striped">
          <caption className='p-4 font-bold text-xl'>Driver List</caption>
          <thead>
            <tr>
              <th>Driver Name</th>
              <th>Phone Number</th>
              <th>Vehicle Model</th>
              <th>Vehicle Number</th>
              <th>Seat Capacity</th>
              <th>Total Amount</th>
              <th>Amount Per Km</th>
              <th>Sell Amount</th>
              <th>Booking ID</th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((driver, index) => (
              <tr key={index}>
                <td>{driver.DriverName}</td>
                <td>{driver.DriverAddress}</td>
                <td>{driver.VehicleModel}</td>
                <td>{driver.VehicleNumber}</td>
                <td>{driver.SeatCapacity}</td>
                <td>{driver.TotalAmount}</td>
                <td>{driver.AmountPerKm}</td>
                <td>{driver.SellAmount}</td>
                <td>{driver.bookingId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddFundData;
