import React from 'react';

export default function RenderInAndroidWebView({ children, fallback }) {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for Android and a custom web view identifier (e.g., 'wv' for Android WebView)
  const isInAndroidWebView = /Android/.test(userAgent) && /(wv|WebView)/.test(userAgent);

  return isInAndroidWebView ? children : (fallback ? fallback : null);
}
