import React from 'react';
import "./About.css"

export default function About() {
  return (
  <>
   <div className="about-banner-img mt-16"></div>
  </>
  )
}
