import React, { useState, useRef, useEffect } from 'react';
import TrackingMap from './TrackingMap';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import axios from 'axios';
import "./locationconverted.css";
import { citylist } from '../env/env';
import { FaSync } from 'react-icons/fa'; // Importing refresh icon
const LocationConverter = () => {
  const [startLocation, setStartLocation] = useState('');
  const [endLocation, setEndLocation] = useState('');
  const [startCoordinates, setStartCoordinates] = useState(null);
  const [endCoordinates, setEndCoordinates] = useState(null);
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [pickupSuggestions, setPickupSuggestions] = useState([]);
  const [dropSuggestions, setDropSuggestions] = useState([]);
  const [distance, setDistance] = useState(null);

  const formRef = useRef(null); // Add a ref to the form section

  useEffect(() => {
    // Scroll to the section if the hash is in the URL
    if (window.location.hash === '#form') {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handlePickupChange = (value) => {
    setStartLocation(value);
    setPickupLocation(value);
    const filteredSuggestions = citylist.filter((city) =>
      city.toLowerCase().includes(value.toLowerCase())
    ).slice(0, 5);
    setPickupSuggestions(filteredSuggestions);
  };

  const handleDropChange = (value) => {
    setDropLocation(value);
    setEndLocation(value);
    const filteredSuggestions = citylist.filter((city) =>
      city.toLowerCase().includes(value.toLowerCase())
    ).slice(0, 5);
    setDropSuggestions(filteredSuggestions);
  };

  const handlePickupSelect = (value) => {
    setPickupLocation(value);
    setPickupSuggestions([]);
  };

  const handleDropSelect = (value) => {
    setDropLocation(value);
    setDropSuggestions([]);
  };

  const mapStyles = {
    height: "400px",
    width: "100%"
  };

  const defaultCenter = {
    lat: 23.2599, // Default latitude
    lng: 77.4126 // Default longitude
  };

  const convertToCoordinates = async (location, setCoordinates) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=AIzaSyCP266W0_rbXWYld5T0YHE7NshSL8mc9-g`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      if (data.results && data.results.length > 0) {
        const coordinates = data.results[0].geometry.location;
        setCoordinates(coordinates);
        console.log('Fetched coordinates:', coordinates);
      } else {
        console.log('Location not found');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const calculateDistance = async (startCoordinates, endCoordinates) => {
    const { lat: startLat, lng: startLng } = startCoordinates;
    const { lat: endLat, lng: endLng } = endCoordinates;
    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=${startLat},${startLng}&destinations=${endLat},${endLng}&key=AIzaSyCP266W0_rbXWYld5T0YHE7NshSL8mc9-g`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      if (data.rows[0].elements[0].status === 'OK') {
        const distance = data.rows[0].elements[0].distance.text;
        setDistance(distance);
        console.log('Calculated distance:', distance);
      } else {
        console.log('Distance calculation failed');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConvertCoordinates = async () => {
    await convertToCoordinates(pickupLocation, setStartCoordinates);
    await convertToCoordinates(dropLocation, setEndCoordinates);
  };

  const handleCalculateDistance = async () => {
    if (startCoordinates && endCoordinates) {
      await calculateDistance(startCoordinates, endCoordinates);
    }
  };

  const handleScrollAndReload = () => {
    // Add hash to URL
    window.location.hash = '#form';
    // Reload the page
    window.location.reload();
  };

  return (
    <>
      <div className='row gap-4 md:gap-0'>
        <div className='col-lg-6 flex items-center justify-center'>
          <div className="location-converter-container" ref={formRef}> {/* Add the ref here */}
            <div className="distance-checker">
              <p className="distance-checker-text">CHECK YOUR JOURNEY DISTANCE</p>
            </div>
            <div className="input-container">
              <label htmlFor="startLocation" className='flex flex-col items-start m-0'>
                Start Location:
                <input
                  type="text"
                  id="startLocation"
                  value={pickupLocation}
                  onChange={(e) => handlePickupChange(e.target.value)}
                  onBlur={() => setPickupSuggestions([])}
                />
                <ul className="suggestions">
                  {pickupSuggestions.map((suggestion, index) => (
                    <li key={index} onMouseDown={() => handlePickupSelect(suggestion)}>
                      {suggestion.split(",")[0]}
                    </li>
                  ))}
                </ul>
              </label>
            </div>
            <div className="input-container">
              <label htmlFor="endLocation" className='flex flex-col items-start m-0'>
                End Location:
                <input
                  type="text"
                  id="endLocation"
                  value={dropLocation}
                  onChange={(e) => handleDropChange(e.target.value)}
                  onBlur={() => setDropSuggestions([])}
                />
                <ul className="suggestions">
                  {dropSuggestions.map((suggestion, index) => (
                    <li key={index} onMouseDown={() => handleDropSelect(suggestion)}>
                      {suggestion.split(",")[0]}
                    </li>
                  ))}
                </ul>
              </label>
            </div>
  
            {/* Direction Button - smaller size */}
            <div className="button-container flex items-center justify-between w-full">
  {/* Direction Button */}
  <button className="convert-button w-3/4" onClick={handleConvertCoordinates}>
    Direction
  </button>

  {/* Refresh Button */}
  <button onClick={handleScrollAndReload} className="refresh-button flex items-center justify-center ml-2">
    <FaSync style={{ color: 'black', fontSize: '20px' }} />
  </button>
</div>

          </div>
        </div>
        <div className='col-lg-6'>
          {startCoordinates && endCoordinates ? (
            <>
              <TrackingMap startCoordinates={startCoordinates} endCoordinates={endCoordinates} distancew={distance} />
            </>
          ) : (
            <LoadScript googleMapsApiKey="AIzaSyCP266W0_rbXWYld5T0YHE7NshSL8mc9-g">
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={10}
                center={defaultCenter}
              />
            </LoadScript>
          )}
        </div>
      </div>
    </>
  );
  
};

export default LocationConverter;
