import React, { useState } from 'react';
// import './ForgotPassword.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [step, setStep] = useState(1);
    const [error, setError] = useState('');

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const formData = new FormData();
            formData.append('email', email);

            const response = await fetch('https://travelshubhankar.pythonanywhere.com/api/forget/', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            if (result.status) {
                sessionStorage.setItem('otp', result.otp);
                sessionStorage.setItem('email', email);
                setStep(2);
            } else {
                setError(result.message);
            }
        } catch (error) {
            setError('Network error');
        }
    };

    const handleOtpSubmit = (e) => {
        e.preventDefault();
        setError('');
        const storedOtp = sessionStorage.getItem('otp');
        if (otp === storedOtp) {
            setStep(3);
        } else {
            setError('Invalid OTP');
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        setError('');
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            const email = sessionStorage.getItem('email');
            const formData = new FormData();
            formData.append('email', email);
            formData.append('password', newPassword);

            const response = await fetch('https://travelshubhankar.pythonanywhere.com/api/reset-password/', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            if (result.status) {
                alert('Password reset successfully');
                setStep(1); // Reset the form to initial step
            } else {
                setError(result.message);
            }
        } catch (error) {
            setError('Network error');
        }
    };
    return (
        <div className="flex items-center justify-center w-screen h-screen">
            {step === 1 && (
                <form onSubmit={handleEmailSubmit} className="flex flex-col items-start p-4 max-w-[400px] w-[50vw] min-w-[300px] gap-4 border rounded-md">
                    <h2 className="font-bold  text-lg">Forgot Password</h2>
                    {error && <p className="error">{error}</p>}
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="border w-full px-4 py-2"
                        required
                    />
                    <button type="submit" className="px-4 py-2 bg-blue-800 rounded-md w-full">Submit</button>
                </form>
            )}

            {step === 2 && (
                <form onSubmit={handleOtpSubmit} className="flex flex-col items-start p-4 max-w-[400px] w-[50vw] min-w-[300px] gap-4 border rounded-md">
                    <h2 className="font-bold  text-lg">Enter OTP</h2>
                    {error && <p className="error">{error}</p>}
                    <input
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="border w-full px-4 py-2"
                        required
                    />
                    <button type="submit" className="px-4 py-2 bg-blue-800 rounded-md w-full">Verify OTP</button>
                </form>
            )}

            {step === 3 && (
                <form onSubmit={handlePasswordSubmit} className="flex flex-col items-start p-4 max-w-[400px] w-[50vw] min-w-[300px] gap-4 border rounded-md">
                    <h2 className="font-bold  text-lg">Reset Password</h2>
                    {error && <p className="error">{error}</p>}
                    <input
                        type="password"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="border w-full px-4 py-2"
                        required
                    />
                    <input
                        type="password"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="border w-full px-4 py-2"
                        required
                    />
                    <button type="submit" className="px-4 py-2 bg-blue-800 rounded-md w-full">Reset Password</button>
                </form>
            )}
        </div>
    );
};

export default ForgotPassword;
