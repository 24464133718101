// TaxiDataDisplay.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { servieUrl } from "../env/env";
import "./TaxiDataModel.css";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import RenderOnAndroid from "./RenderOnAndroid";
const MyBooking = () => {
  const [taxiData, setTaxiData] = useState([]);
  const [open, setOpen] = useState(true);
  const [booking, setBooking] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const localData = localStorage.getItem("userData");
  const phoneNumber = "+918210888071";
  const registrationNumber = "+917440800644";
  const navigate = useNavigate();

  const fetchData = async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleWhatsappChat = () => {
    const defaultText = encodeURIComponent(
      "Hello! I wanted to reach out to you."
    );
    const whatsappUrl = `https://api.whatsapp.com/send/?phone=+91-7440800644&text=fd`;
    window.open(whatsappUrl, "_blank");
  };

  const handleCall = () => {
    const telUrl = `tel:${registrationNumber}`;
    window.open(telUrl, "_blank");
  };

  useEffect(() => {
    if (!localData) {
      navigate("/loginRegistration");
    }
  }, [localData, navigate]);

  useEffect(() => {
    fetchData(servieUrl.url + "api/get_taxi/", setTaxiData);
    fetchDrivers();
  }, []);

  useEffect(() => {
    fetchData(servieUrl.url + "api/drivers/", setDriverData);
  }, [booking]);
  const [drivers, setDrivers] = useState([]);
  const RefreshHandle = () => {
    window.location.reload();
  };
const [driverone,setDriverone]=useState([]);


  const handleLogout = () => {
    // Remove user data from localStorage
    localStorage.clear()  // any other data

    // Redirect to the home page
    navigate('/');
  };

  const GetTaxi = async (bookingId) => {
    setOpen(!open);
    try {
      const response = await axios.get(`https://travelshubhankar.pythonanywhere.com/api/drivers/${bookingId}/`);
      setDriverone(response.data);
    } catch (error) {
      console.error(`Error fetching driver for booking ${bookingId}:`, error);
    }
  };
  
  const fetchDrivers = async () => {
    try {
      const response = await axios.get('https://travelshubhankar.pythonanywhere.com/api/drivers/');
      setDrivers(response.data);
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const BackHandle = () => {
    setOpen(!open);
  };

  const filteredData = taxiData.filter((item) => item.username === localData);

  return (
    <>
      <div className="current-data-bg mt-16">
        {open ? (
          <div className="min-h-screen flex items-center justify-center">
            <div className="current-data bg-white bg-opacity-10 shadow rounded p-5 w-full mx-5 md:mx-14 lg:mx-20 xl:mx-28">
              <div className="flex justify-between">
                <h2 className="text-2xl font-bold text-[#FFC61A]">
                  <span> Your Booked</span>
                  <FontAwesomeIcon
                    icon={faArrowsRotate}
                    className="ml-2"
                    onClick={RefreshHandle}
                  />
                </h2>
                <RenderOnAndroid fallback={
  // Render this if NOT on Android devices
  <div className="hidden md:block">
    {/* This part will NOT render in Android */}
    <button
      className="bg-[#25d366] text-white px-4 py-2 font-semibold mr-4 rounded-xl"
      onClick={handleWhatsappChat}
    >
      Chat With Whatsapp
    </button>
    <button
      className="bg-blue-400 text-white px-4 py-2 font-semibold rounded-xl"
      onClick={handleCall}
    >
      Call US
    </button>
  </div>
}>
  {/* This part will render ONLY on Android devices */}
  <div className="h-14 flex items-center justify-center">
    <button
      className="bg-red-500 text-white px-4 py-2 font-semibold rounded-xl"
      onClick={handleLogout}
    >
      Logout
    </button>
  </div>
</RenderOnAndroid>

              </div>

              <div className="block md:hidden mt-4">
                <p>
                  <button
                    className="bg-[#25d366] w-full text-white px-4 py-2 font-semibold rounded-xl"
                    onClick={handleWhatsappChat}
                  >
                    Chat With Whatsapp
                  </button>
                </p>

                <p className="mt-4">
                  <button
                    className="bg-blue-400 w-full text-white px-4 py-2 font-semibold rounded-xl"
                    onClick={handleCall}
                  >
                    Call US
                  </button>
                </p>
              </div>

              <div className="overflow-x-auto ">
                <table className="min-w-full mt-5">
                  <thead>
                    <tr className="text-black">
                      <th className="border p-4">Booking Id</th>
                      <th className="border p-4">From Address</th>
                      <th className="border p-4">To Address</th>
                      <th className="border p-4">Date</th>
                      <th className="border p-4">Time</th>
                      <th className="border p-4">Phone</th>
                      <th className="border p-4">Name</th>
                      <th className="border p-4">Status</th>
                      <th className="border p-4">DriverDetails</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((dataItem, index) =>
                     {
                      const driver = drivers.find(driver => driver.bookingId === dataItem.bookingId);
                      
                      return (
                        <tr key={index} className="text-white">
                          <td className="border p-4">{dataItem.bookingId}</td>
                          <td className="border p-4">{dataItem.fromaddress}</td>
                          <td className="border p-4">{dataItem.toaddress}</td>
                          <td className="border p-4">{dataItem.date}</td>
                          <td className="border p-4">{dataItem.time}</td>
                          <td className="border p-4">{dataItem.phone}</td>
                          <td className="border p-4">{dataItem.name}</td>
                      
                          {dataItem.action === "active" ? (
                            <td className="border" onClick={() => GetTaxi(driver)}>
                              <div className="flex justify-center">
                                <span className="cursor-pointer bg-yellow-400 text-white font-semibold rounded m-5 xl:mx-0 px-4 py-1">
                                  Approved
                                </span>
                              </div>
                            </td>
                          ) : dataItem.action === "pending" ? (
                            <td className="border">
                              <div className="flex justify-center">
                                <span className="cursor-pointer bg-red-600 text-white font-semibold rounded mx-5 xl:mx-0 px-4 py-1">
                                  Pending
                                </span>
                              </div>
                            </td>
                          ) : dataItem.action === "Complete" ? (
                            <td className="border">
                              <div className="flex justify-center">
                                <span className="cursor-pointer bg-green-600 text-white font-semibold rounded mx-5 xl:mx-0 px-4 py-1">
                                  Completed
                                </span>
                              </div>
                            </td>
                          ) : dataItem.action === "unActive" ? (
                            <td className="border">
                              <div className="flex justify-center">
                                <span className="cursor-pointer bg-gray-400 text-white font-semibold rounded mx-5 xl:mx-0 px-4 py-1">
                                  InActive
                                </span>
                              </div>
                            </td>
                          ) : null}
                      
                          <td>
                            {dataItem.driverId !== "False" ? (
                              <td className="border" onClick={() => GetTaxi(dataItem.driverId)}>
                                <div className="flex justify-center">
                                  <span className="cursor-pointer bg-yellow-400 text-white font-semibold rounded m-5 xl:mx-0 px-4 py-1">
                                    Details
                                  </span>
                                </div>
                              </td>
                            ) : (
                              "Not assigned"
                            )}
                          </td>
                        </tr>
                      );
                      
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center min-h-screen">
            <div className="current-data bg-white bg-opacity-10 shadow rounded p-5 w-full mx-5 md:mx-14 lg:mx-20 xl:mx-28">
              <Link onClick={BackHandle} className="text-white">
                {/* Back Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </Link>

              <div className="flex justify-between mt-4">
                <h2 className="text-2xl font-bold  text-[#FFC61A]">
                  Your Booking Detail
                </h2>
                <div className="hidden md:block">
                  <button
                    className="bg-[#25d366] text-white px-4 py-2 font-semibold mr-4 rounded-xl"
                    onClick={handleWhatsappChat}
                  >
                    Chat With Whatsapp
                  </button>
                  <button
                    className="bg-blue-400 text-white px-4 py-2 font-semibold rounded-xl"
                    onClick={handleCall}
                  >
                    Call US
                  </button>
                </div>
              </div>

              <div className="block md:hidden mt-4">
                <p>
                  <button
                    className="bg-[#25d366] w-full text-white px-4 py-2 font-semibold rounded-xl"
                    onClick={handleWhatsappChat}
                  >
                    Chat With Whatsapp
                  </button>
                </p>

                <p className="mt-4">
                  <button
                    className="bg-blue-400 w-full text-white px-4 py-2 font-semibold rounded-xl"
                    onClick={handleCall}
                  >
                    Call US
                  </button>
                </p>
              </div>

              <div className="overflow-x-auto ">
              <table className="min-w-full mt-5 no-hover-effect">
  <thead>
    <tr className="">
      <th className="p-4 border">Driver ID</th>
      <th className="p-4 border">Driver Name</th>
      <th className="p-4 border">Phone Number</th>
      <th className="p-4 border">Vehicle Model</th>
      <th className="p-4 border">Vehicle Number</th>
      <th className="p-4 border">Seat Capacity</th>
      <th className="p-4 border">Total Amount</th>
      <th className="p-4 border">Amount Per Km</th>
      <th className="p-4 border">Sell Amount</th>
      <th className="p-4 border">Booking ID</th>
    </tr>
  </thead>
  <tbody className="hover-text-white">
  {driverone && (
    <tr key={driverone.DriverId} className="text-white">
      <td className="p-4 border">{driverone.DriverId}</td>
      <td className="p-4 border">{driverone.DriverName}</td>
      <td className="p-4 border">{driverone.DriverAddress}</td>
      <td className="p-4 border">{driverone.VehicleModel}</td>
      <td className="p-4 border">{driverone.VehicleNumber}</td>
      <td className="p-4 border">{driverone.SeatCapacity}</td>
      <td className="p-4 border">{driverone.TotalAmount}</td>
      <td className="p-4 border">{driverone.AmountPerKm}</td>
      <td className="p-4 border">{driverone.SellAmount}</td>
      <td className="p-4 border">{driverone.bookingId}</td>
    </tr>
  )}
</tbody>

</table>


              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyBooking;
