import React from 'react';
import './TermsConditions.css';

const TermsConditions = () => {
    return (
        <div className="flex flex-col gap-4 items-start p-4 md:pt-24 pt-8">
            <h1 className='text-3xl font-bold'>Terms & Conditions</h1>
            <div className="flex flex-col gap-8 p-2 md:p-4">
                <div className="flex gap-2 flex-col">
                    <h5 className='font-bold text-lg'>Welcome to our website</h5>
                    <p>
                        When continuing with terms and conditions you agree to comply with and be bound by the following terms and conditions which are together with our privacy policy govern Shubhankar Travel's relationship with you in relation to this website. If you disagree or in case of any issues, please write to us at travelshubhankar@gmail.com or call us at +91-7440800644.
                    </p>
                </div>

                <div className="flex gap-2 flex-col">
                    <h5 className='font-bold text-lg'>Ownership</h5>
                    <p>
                        The term 'Shubhankar Travel' refers to the ‘Car travel services” that we provide and our office is located in Saket Nagar, Bhopal, (MP).
                    </p>
                </div>

                <div className="flex gap-2 flex-col">
                    <h5 className='font-bold text-lg'>Terms of Use</h5>
                    <ul className='md:px-8 pt-0 pb-0 px-4'>
                        <li>1. This website uses cookies to monitor browsing preferences. If you do allow cookies to be used by this website to track user preferences, should you consent to the use of cookies, we may keep the following personal data for use by outside parties: IP Address, Location.</li>
                        <li>2. The information and contents found or made available on this website are not warranted or guaranteed to be accurate, timely, complete, or suitable for any particular purpose by us or any third parties. To the maximum extent permissible by law, you acknowledge that such information and materials may contain mistakes or inaccuracies, and we specifically disclaim any liability for any such errors or inaccuracies. Also, be informed in case of legalities we are only bound in M.P state and in particular only in Bhopal jurisdiction. In case of any issues, please write to us at travelshubhankar@gmail.com or call us at +91-7440800644.</li>
                        <li>3. Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services, or information available through this website meet your specific requirements.</li>
                        <li>4. This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                        <li>5. All trademarks reproduced in this website which are not the property of, or licensed to, the operator have been taken from open available sources only and will be removed in such case & has been acknowledged on the website.</li>
                        <li>6. Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
                        <li>7. Your use of this website and any dispute arising out of such use of the website is subject to the laws of India, specifically to only Bhopal jurisdiction.</li>
                        <li>8. Specific offers might have additional Terms & Conditions which the user has to comply with in case he/she chooses to avail that offer.</li>
                        <li>9. Please make sure that you are travelling through our own authorized drivers only and if any other driver comes for your pickup, he shall be confirmed by the authority that it’s our third-party genuine vendor or service provider and is a part of our agreement, over the call/ chat or in person.</li>
                        <li>10. The utilization and protection of data we employ common, industry-wide, commercially reasonable security measures, like encryption, firewalls, and SSL (Secure Socket Layers), to protect the information we gather, and it is safely kept within our databases. Even with encryption technology's effectiveness, no security system is unbreakable. You communicate any information to the Company at your own risk. We make no guarantees about the security of our databases or that it won't be intercepted while being transmitted to us over the Internet or wireless connection. It is advised that you keep your password private.</li>
                    </ul>
                </div>

                <div className="flex gap-2 flex-col">
                    <h5 className='font-bold text-lg'>Cancellation and Returns</h5>
                    <ul className='md:px-8 pt-0 pb-0 px-4'>
                        <li>1. You may cancel the booking over the call only, within the 1st hour of the booking to avoid any deductions/penalties. In case of cancellation after 1 hr., 75% of the total advance will be given back only.</li>
                        <li>2. Multi-Day Trip: The night charge of the driver, toll, and parking is separate from the total amount of the journey and will be intimated over the chat, call, or in person while you are booking the trip with us.</li>
                    </ul>
                </div>

                <div className="flex gap-2 flex-col">
                    <h5 className='font-bold text-lg'>Refunds</h5>
                    <p>
                        If you are eligible for refunds based on the “Cancellation and Returns” policy above, then the refund will be remitted back to you in 1-2 working days. In case of any issues, write to us at travelshubhankar@gmail.com or call us at +91-7440800644.
                    </p>
                </div>
            </div>
        </div>
    )
};

export default TermsConditions;
