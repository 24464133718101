import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import DmForm from "./DmForm";
import "./Dm.css"; // Import CSS file for styling
import slide1 from "../Assests/Homepageimages/slider1.jpg";
import slide2 from "../Assests/Homepageimages/slider2.jpg";
import slide3 from "../Assests/Homepageimages/slider3.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

function Dm() {
  const sliderRef = useRef(null);

  // Initialize state with default photos
  const [images, setImages] = useState({
    photo1: slide1,
    photo2: slide2,
    photo3: slide3,
  });

  useEffect(() => {
    // Fetch the latest upload images from the API
    fetch("https://travelshubhankar.pythonanywhere.com/api/api/latest-upload/")
      .then((response) => response.json())
      .then((data) => {
        setImages({
          photo1: data.photo1 || slide1,
          photo2: data.photo2 || slide2,
          photo3: data.photo3 || slide3,
        });
      })
      .catch((error) => {
        console.error("Error fetching the images:", error);
      });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <Slider ref={sliderRef} {...settings} className="overflow-hidden slider-container h-[90vh]">
        <div className="slide h-screen">
          <div className="image-container h-screen w-[100%]">
            <img src={images.photo1} alt="Image 1" className="md:w-[100%] md:h-auto h-full w-auto max-w-[none]" />
          </div>
        </div>
        <div className="slide h-screen">
          <div className="image-container h-screen">
            <img src={images.photo2} alt="Image 2" className="md:w-[100%] md:h-auto h-full w-auto max-w-[none]" />
          </div>
        </div>
        <div className="slide h-screen">
          <div className="image-container h-screen">
            <img src={images.photo3} alt="Image 3" className="md:w-[100%] md:h-auto h-full w-auto max-w-[none]" />
          </div>
        </div>
      </Slider>

      <div className="absolute top-0 left-0 z-0 h-[90vh] w-screen">
        <DmForm />
      </div>

      <div className="hidden md:flex absolute top-1/2 transform -translate-y-1/2 left-0 z-10">
        <button className="text-white px-4 py-2 rounded-l" onClick={goToPrevSlide}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      </div>
      <div className="hidden md:flex absolute top-1/2 transform -translate-y-1/2 right-0 z-10">
        <button className="text-white px-4 py-2 rounded-r" onClick={goToNextSlide}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </>
  );
}

export default Dm;
