import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../Common/Images/Logo.jpeg";
import Login from "../Components/Login";
import Booking from "../Components/Booking";
import './Navbar.css'
import RenderOnAndroid from "../Components/RenderOnAndroid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faStar, faPhone, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isBusinessOpen, setIsBusinessOpen] = useState(false);
  const [isTrainingOpen, setIsTrainingOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activenav, setActivenav] = useState('/');
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location?.pathname)

  const closeDropdowns = () => { };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 40) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    setActivenav(location.pathname);
  }, [location.pathname]);


  const [isLoginFormVisible, setIsLoginFormVisible] = useState(false);
  const openLoginForm = () => {
    setIsLoginFormVisible(true);
    setIsDrawerOpen(false);
  };


  const [isBookingModalVisible, setIsBookingModalVisible] = useState(false);
  const openBookingModal = () => {
    setIsBookingModalVisible(true);
    setIsDrawerOpen(false);
  };

  const LogoutHandle = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("userData");

    navigate("/");
  };

  const [isLogoutVisible, setIsLogoutVisible] = useState(false);

  const handleLogoutClick = () => {
    setIsLogoutVisible(true);
  };

  const handleCancelClick = () => {
    setIsLogoutVisible(false);
  };

  const handleLogoutSubmit = (e) => {
    e.preventDefault();
    localStorage.removeItem("login");
    localStorage.removeItem("userData");

    navigate("/");
    setIsLogoutVisible(false);
  };

  const MobileNav = () => <div className="fixed bottom-0 left-0 w-full flex items-center justify-around p-4 bg-blue-500 z-50 h-[4rem]">
    <NavLink to="/" className={({ isActive }) => isActive ? "text-white" : "text-white/75"}>
      <FontAwesomeIcon icon={faHouse} />
    </NavLink>
    <NavLink to="/services" className={({ isActive }) => isActive ? "text-white" : "text-white/75"}>
      <FontAwesomeIcon icon={faStar} />
    </NavLink>
    <NavLink to="/contact1" className={({ isActive }) => isActive ? "text-white" : "text-white/75"}>
      <FontAwesomeIcon icon={faPhone} />
    </NavLink>
    {
      localStorage.getItem("userData") ? (<NavLink to="/allBooking" className={({ isActive }) => isActive ? "text-white" : "text-white/75"}>
      <FontAwesomeIcon icon={faUserPlus} />
      </NavLink>):<Link
      onClick={() => openLoginForm()}
      className="text-white"
    >
      <FontAwesomeIcon icon={faUserPlus} />
    </Link>
    }
    
    
  </div>;


  const DesktopNav = () => <>
    <nav
      className={`fixed top-0 left-0 right-0 z-50 ${isScrolled
        ? "bg-black text-white shadow-b-lg px-5 py-2 sm:px-10 sm:py-2 md:px-20 md:py-3 lg:px-28"
        : "bg-black text-white shadow-b-lg  px-5 py-2 sm:px-10 sm:py-2 md:px-20 md:py-3 lg:px-28"
        }`}
    >
      {/* <Scrollup /> */}
      <div className="flex justify-between items-center">
        <div className="flex gap-2">


          <Link to="/">
            {isScrolled ? (
              <img
                className="logo-size w-[50px] h-auto cursor-pointer rounded-full"
                src={Logo}
                alt=""
              />
            ) : (
              <img
                className="logo-size w-[50px] bg-white p-1  h-auto cursor-pointer rounded-full"
                src={Logo}
                alt=""
              />
            )}
          </Link>

          <div className="ShubhankarName flex flex-col">
            <h3 className="text-xl font-semibold fontstyle ShubhankarName">Shubhankar Travel</h3>
            <p className="text-sm ShubhankarName">Safe & Smooth Journey, Our Priority</p>
          </div>
        </div>
        <div className="lg:hidden">
          {/* Hamburger Icon */}

          <button
            className="p-2 focus:outline-none third-dropdown-button"
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>

        {/* Drawer */}

        {isDrawerOpen && (
          <div className="lg:hidden absolute top-0 right-0 h-screen w-64 bg-[#DDDDDD] text-black p-4 shadow-md">
            <button
              className="text-white p-2 focus:outline-none absolute top-2 right-2"
              onClick={() => setIsDrawerOpen(false)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>

            <ul className="space-y-3 flex items-center">
              <li>
                <Link
                  to="/"
                  className={`hover:text-blue-500 ${activenav === "/" ? "text-blue-500" : null}`}
                  onClick={() => { setIsDrawerOpen(false); setActivenav("/") }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className={`hover:text-blue-500 ${activenav === "/services" ? "text-blue-500" : null}`}
                  onClick={() => { setIsDrawerOpen(false) }}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/contact1"
                  className={`hover:text-blue-500 ${activenav === "/contact" ? "text-blue-500" : null}`}
                  onClick={() => { setIsDrawerOpen(false) }}
                >
                  Contact
                </Link>
              </li>
              {localStorage.getItem("userData") === null ? (
                <li>
                  <button
                    onClick={() => openLoginForm()}
                    className="hover:text-blue-500 font-bold"
                  >
                    Login/register
                  </button>
                </li>
              ) : null}

              {localStorage.getItem("userData") !== null &&
                localStorage.getItem("userData") !== "" ? (
                <li>
                  <Link to="/allBooking" className={`hover:text-blue-500 ${activenav === "/allBooking" ? "text-blue-500" : null}`}>
                    My Booking
                  </Link>
                </li>
              ) : null}
              {localStorage.getItem("userData") !== null &&
                localStorage.getItem("userData") !== "" ? (
                <li>
                  <button
                    onClick={() => handleLogoutClick()}
                    className="hover:text-blue-500"
                  >
                    Logout
                  </button>
                </li>
              ) : null}
            </ul>
          </div>
        )}

        {/* for desktop */}

        <ul className="hidden lg:flex text-md font-medium items-center gap-4">
          <li>
            <Link
              to="/"
              className={`hover:text-blue-500 ${activenav === "/" ? "text-blue-500" : null}`}
              onClick={() => closeDropdowns()}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              className={`hover:text-blue-500 ${activenav === "/services" ? "text-blue-500" : null}`}
              onClick={() => closeDropdowns()}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/contact1"
              className={`hover:text-blue-500 ${activenav === "/contact" ? "text-blue-500" : null}`}
              onClick={() => closeDropdowns()}
            >
              Contact
            </Link>
          </li>
          {console.log(localStorage.getItem("userData"))}
          {localStorage.getItem("userData") === null ? (
            <li>
              <Link
                onClick={() => openLoginForm()}
                className="hover:text-blue-500 font-bold"

              >
                Login/Register
              </Link>
            </li>
          ) : null}

          {localStorage.getItem("userData") !== null &&
            localStorage.getItem("userData") !== "" ? (
            <li>
              <Link to="/allBooking" className={`hover:text-blue-500 ${activenav === "/allBooking" ? "text-blue-500" : null}`}>
                My Booking
              </Link>
            </li>
          ) : null}
          {localStorage.getItem("userData") !== null &&
            localStorage.getItem("userData") !== "" ? (
            <li>
              <button
                onClick={() => handleLogoutClick()}
                className="hover:text-blue-500"
              >
                LogOut
              </button>
            </li>
          ) : null}
        </ul>
      </div>
    </nav>

    {isLoginFormVisible && (
      <Login onClose={() => setIsLoginFormVisible(false)} />
    )}

    {/* Booking Modal */}
    {isBookingModalVisible && (
      <Booking onClose={() => setIsBookingModalVisible(false)} />
    )}

    {isLogoutVisible && (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
        <div className="bg-white p-8 rounded shadow-lg">
          <h2 className="text-2xl mb-4">Are you sure?</h2>

          <button
            type="button"
            onClick={handleCancelClick}
            className="bg-red-500 text-white px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={handleLogoutSubmit}
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            LogOut
          </button>
        </div>
      </div>
    )}
  </>
  return (
    <RenderOnAndroid fallback={
      <DesktopNav />
    }>
      <MobileNav />

      {isLoginFormVisible && (
        <Login onClose={() => setIsLoginFormVisible(false)} />
      )}

    </RenderOnAndroid>
  );
};

export default Navbar;
