import React, { useEffect, useState } from 'react';
import './Admin.css'; // Import your CSS file for styling
import axios from 'axios';
import AddFundData from './AddFund/AddFund';
import BookingDetails from './Bookings/BookingDetails';
import * as XLSX from 'xlsx';
import QRCodeGenerator from './qr/QRCodeGenerator';

const AdminPanel = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [viewData, setViewData] = useState('drivers'); // Default view is set to 'drivers'
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (viewData === 'user') {
      fetchUsers();
    }
  }, [viewData]);

  const fetchUsers = async () => {
    try {
      const response = await fetch('https://travelshubhankar.pythonanywhere.com/api/users/');
      const jsonData = await response.json();
      setUsers(jsonData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleLogin = () => {
    // Perform login verification here
    if (username === 'admin' && password === 'admin') {
      setLoggedIn(true);
    } else {
      alert('Invalid username or password');
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setUsername('');
    setPassword('');
  };

  const handleTabClick = (tab) => {
    setViewData(tab);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(users);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users Data');
    XLSX.writeFile(workbook, 'UsersData.xlsx');
  };

  return (
    <div className="min-h-screen h-fit w-screen flex flex-col items-center justify-center relative pt-20">
      {!loggedIn ? (
        <div className='h-screen flex items-center justify-center'>
          <div className="login-form">
            <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button onClick={handleLogin}>Login</button>
          </div>
        </div>
      ) : (
        <>
          <div className='flex flex-col p-2 w-full justify-start flex-1'>
            <div className="flex flex-row w-full items-center sticky top-[4.5rem] p-2 bg-white">
              <div className="flex items-center flex-1">
                <button className={`tab ${viewData === 'drivers' ? 'active' : ''}`} onClick={() => handleTabClick('drivers')}>
                  Drivers
                </button>
                <button className={`tab ${viewData === 'bookings' ? 'active' : ''}`} onClick={() => handleTabClick('bookings')}>
                  Bookings
                </button>
                <button className={`tab ${viewData === 'user' ? 'active' : ''}`} onClick={() => handleTabClick('user')}>
                  Users
                </button>
                <button className={`tab ${viewData === 'qr' ? 'active' : ''}`} onClick={() => handleTabClick('qr')}>
                  QR
                </button>
              </div>
              <button className='btn btn-danger !mb-0' onClick={handleLogout}>Logout</button>
            </div>
            <div className="data-table p-4">
              {viewData === 'user' && (
                <>
                  <button onClick={exportToExcel} className="btn btn-primary mb-4">Export to Excel</button>
                  <table>
                    <caption className='p-4 font-bold text-xl'>Users Details</caption>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Full Name</th>
                        <th>Phone Number</th>
                        <th>Address</th>
                        <th>Date Joined</th>
                        <th>Is Active</th>
                        <th>Dob</th>
                        <th>Driver ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map(user => (
                        <tr key={user.id}>
                          <td>{user.id}</td>
                          <td>{user.username}</td>
                          <td>{user.email}</td>
                          <td>{user.fullname}</td>
                          <td>{user.phone_number}</td>
                          <td>{user.address}</td>
                          <td>{new Date(user.date_joined).toLocaleString()}</td>
                          <td>{user.is_active ? 'Yes' : 'No'}</td>
                          <td>{user.dob}</td>
                          <td>{user.DriverId}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              {viewData === 'drivers' && (
                <AddFundData />
              )}
              {viewData === 'bookings' && (
                <BookingDetails />
              )}
              {viewData === 'qr' && (
                <QRCodeGenerator />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminPanel;
