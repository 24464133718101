import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
  faComments
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../Common/Images/Logo.jpeg";
import "./style.css";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import ChatBot from 'react-simple-chatbot';
export default function Footer() {
  const location = useLocation();
  // const flag = location?.pathname?.includes("/location") || location?.pathname?.includes("/login");
  const flag = location?.pathname?.includes("/location");
  const steps = [
    {
      id: '0',
      message: 'Welcome to react chatbot!',
      trigger: '1',
    },
    {
      id: '1',
      message: 'Bye!',
      end: true,
    },
  ];

  if (flag) {
    return <></>;
  } else {
    return (
      <>
        <div className="footer-part p-12 bg-black mt-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 mx-0 md:mx-12 lg:mx-14">
            <div>
              {/* <h4 className="text-[#f0bb3a] text-3xl font-bold">Taxi</h4> */}
              <img src={Logo} className="w-[60px] h-auto"></img>

              <h5 className="mt-3 foot-head  text-white ">
                <Link to="/About">About Us</Link>
              </h5>

              <p className="mt-3  text-white">
                Where quality meets affordability.
                <br /> We understand the importance of
                <br />
                a smooth and enjoyable journey
                <br /> without the burden
                <br /> of excessive costs.
              </p>
            </div>

            <div className="mt-5 md:mt-4 lg:mt-0">
              <h5 className="text-white foot-head">Legal</h5>

              <div className="mt-2 md:mt-4 text-white">
                {/* <Link to="/TermsConditions">
                  <p className="">Terms & Conditions</p>
                </Link> */}
                <p className="">
                  <Link to="/TermsConditions">Terms & Conditions</Link>
                </p>

                {/* <Link to="/PrivacyPolicy">
                  <p className="mt-2">Privacy Policy</p>
                </Link> */}
                {/* <p className="">
                  <Link to="/PrivacyPolicy">Privacy Policy</Link>
                </p> */}
              </div>
            </div>

            <div className="mt-5 md:mt-4 lg:mt-0">
              <h5 className="text-white foot-head">Get In Touch</h5>

              <div className="mt-2 md:mt-4 text-white">
                <p className="">
                  <Link to="/contact1">Contact Us</Link>
                </p>
              </div>
            </div>

            <div className="mt-5 md:mt-4 lg:mt-0">
              <h5 className="text-white foot-head">Quick Links</h5>

              <div className="mt-2 md:mt-4 text-white">
                <p className="">
                  <Link to="/">Home</Link>
                </p>

                <p className="mt-2">
                  {" "}
                  <Link to="/about">About</Link>
                </p>

                <p className="mt-2">
                  <Link to="/services">Services</Link>
                </p>
              </div>
            </div>

            <div className="mt-5 md:mt-4 lg:mt-0">
              <h5 className="text-white contact-us">
                <Link to="/contact1">Contact Us</Link>
              </h5>

              <ul className="list-inline footer-contact mt-2 md:mt-4">
                <li className="mb-3">
                  <a href="tel:+91-7440800644">
                    <FontAwesomeIcon icon={faPhone} className="footer-icon" />
                    +91-7440800644
                  </a>
                </li>

                <li className="mb-3">
                  <a href="mailto:travelshubhankar@gmail.com">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="footer-icon"
                    />
                travelshubhankar@gmail.com
                  </a>
                </li>

                <li className="mb-3">
                  <Link to="">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="footer-icon"
                    />
                   Saket Nagar, Habibganj near Rani Kamlaapati station (RKMP) & ISBT, Bhopal, Madhya Pradesh 462024.
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <hr className="my-5 w-100 text-white "></hr>

          <div className="flex flex-row justify-between flex-wrap">
            <div>
              <div className="social-link">
                <Link to="">
                  <FontAwesomeIcon icon={faFacebook} className="social-icon" />
                </Link>

                <Link to="">
                  <FontAwesomeIcon icon={faInstagram} className="social-icon" />
                </Link>

                {/* <Link to="">
                  <FontAwesomeIcon icon={faTwitter} className="social-icon" />
                </Link> */}

                <Link to="">
                  <FontAwesomeIcon icon={faWhatsapp} className="social-icon" />
                </Link>
              </div>
            </div>

            <div>
              <p className="footer-paragraph text-white">
                Copyright@2023 All Rights Reserved By :
                <Link to="/" className="footer-text">
                  <strong>Taxi</strong>
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="fixed md:absolute  bottom-10 md:bottom-9 md:right-9 right-0 min-w-[300px]">
          {/* <ChatBot
            headerTitle="Let's Chat"
            floating="true"
            cache={false}
            steps={[
              {
                id: '1',
                message: 'Hello sir how can I help you?',
                trigger: "options",
              },
              {
                id: 'options',
                options: [
                  { value: "dest", label: "help me with destination", trigger: "dest" },
                  { value: "taxi", label: "help me with taxi", trigger: "taxi" }
                ]
              },
              {
                id: "dest",
                message: "Kashmir will be a good destination in summer season",
                end: true
              },
              {
                id: "taxi",
                message: "for family SUV is a good option and for couple sydan is a great choice",
                end: true
              }
            ]}
          /> */}
          <div className="custom-whatsapp">
          <FloatingWhatsApp
      phoneNumber="+91-7440800644" // replace with the phone number you want to use
        accountName="Shubhankar Travel" // replace with the desired account name
        // Add other props as needed
        avatar={Logo}
      />
          </div>
        
        </div>
      </>
    );
  }
}
