import React from 'react';
import Navbar from "./Common/Navbar";
import {Link, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Services from "./Components/Services";
import Footer from "./Common/Footer";
import Location from "./Components/Location";
import Booking from "./Components/Booking";
import TaxiDataModal from "./Components/TaxiDataModal";
import MyBooking from "./Components/MyBooking";
import RegistrationLogin from "./Components/RegistrationLogin";
import GoogleLocation from "./Components/GoogleLocation";
import InvoiceForm from "./Components/InvoiceForm";
import ProfileCard from "./Components/ProfileCard";
import Calculate from "./Components/Calculate";
import TermsConditions from "./Components/TermsConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import ScrollToTop from "react-scroll-to-top";
import AdminPanel from "./Components/Admin/Admin";
import RenderOnAndroid from "./Components/RenderOnAndroid";
import ForgotPassword from "./Components/Forget";
import Logo from "./Common/Images/Logo.jpeg"
import HomePageSection from './App/HomePageSection';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
function App() {
  return (
    <>
      <RenderOnAndroid>
      <div className="flex gap-2">


<Link to="/">
 
    <img
      className="logo-size w-[50px] h-auto cursor-pointer rounded-full"
      src={Logo}
      alt=""
    />
 
</Link>

<div className="ShubhankarName flex flex-col">
  <h3 className="text-xl font-semibold fontstyle ShubhankarName">Shubhankar Travel</h3>
  <p className="text-sm ShubhankarName">Safe & Smooth Journey, Our Priority</p>
</div>
</div>
          </RenderOnAndroid>
       
      <Navbar />
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact1" element={
          
            <Contact />
         
        } />
        <Route path="/contact" element={
          <RenderOnAndroid>
            <Contact />
          </RenderOnAndroid>
        } />
        <Route path="/services" element={<Services />} />
        <Route path="/location" element={<Location />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/currentData" element={<TaxiDataModal />} />
        <Route path="/allBooking" element={<MyBooking />} />
        <Route path="/loginRegistration" element={<RegistrationLogin />} />
        <Route path="/map" element={<GoogleLocation />} />
        <Route path="/invoice" element={<InvoiceForm />} />
        <Route path="/profile" element={<ProfileCard />} />
        <Route path="/cal" element={<Calculate />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route path="/TermsConditions" element={<TermsConditions />} />
      
        <Route path="/admin" element={<AdminPanel />}></Route>
        <Route path="/forget" element={<ForgotPassword />}></Route>
      </Routes>
      <RenderOnAndroid fallback={  <HomePageSection/>}>
        <div className="h-14"></div>
      </RenderOnAndroid>
    
      <RenderOnAndroid fallback={<Footer />}>
        <div className="h-14"></div>
      </RenderOnAndroid>
     
      <ScrollToTop smooth className="scroll-to-top-button" />
    </>
  );
}

export default App;
