export const servieUrl = {
  // url: "https://travelshubhankar.pythonanywhere.com/",
  "url":"https://travelshubhankar.pythonanywhere.com/",
};

export const citylist=[ "Adoni, AP",
  "Amarpatan, MP",
  "Banda, UP",
  "Betul, MP",
  "Bhikangaon, MP",
  "Bhind, MP",
  "Biaora, MP",
  "Burhanpur, MP",
  "Datia, MP",
  "Guna, MP",
  "Hoshangabad, MP",
  "Jaisinghnagar, MP",
  "Khandwa, MP",
  "Khargone, MP",
  "Mangawan, MP",
  "Morena, MP",
  "Nagda, MP",
  "Neemuch, MP",
  "Rehli, MP",
  "Sarangpur, MP",
  "Satna, MP",
  "Sehore, MP",
  "Seoni, MP",
  "Singrauli, MP",
  "Agar, MP",
  "Agar Malwa, MP",
  "Agartala, TR",
  "Agra, UP",
  "Ahmedabad, GJ",
  "Ahmednagar, MH",
  "Aizawl, MZ",
  "Ajmer, RJ",
  "Akola, MH",
  "Alappuzha, KL",
  "Aligarh, UP",
  "Alwar, RJ",
  "Amaravati, AP",
  "Ambala, HR",
  "Ambattur, TN",
  "Ambernath, MH",
  "Amravati, MH",
  "Amritsar, PB",
  "Amroha, UP",
  "Anand, GJ",
  "Anantapur, AP",
  "Anuppur, MP",
  "Arrah, BR",
  "Asansol, WB",
  "Ashapur, MP",
  "Ashoknagar, MP",
  "Ashta, MP",
  "Aurangabad, MH",
  "Avadi, TN",
  "Babai, MP",
  "Bada Malhera, MP",
  "Badnawar, MP",
  "Badora, MP",
  "Badwani, MP",
  "Bagaha, BR",
  "Bagli, MP",
  "Baharampur, WB",
  "Bahraich, UP",
  "Balaghat, MP",
  "Bally, WB",
  "Bamora, MP",
  "Bangalore, KA",
  "Baranagar, WB",
  "Barasat, WB",
  "Bardhaman, WB",
  "Bareily, UP",
  "Barigarh, MP",
  "Barkheda, MP",
  "Barwani, MP",
  "Bathinda, PB",
  "Begusarai, BR",
  "Belgaum, KA",
  "Bellary, KA",
  "Berhampur, WB",
  "Bettiah, BR",
  "Bhagalpur, BR",
  "Bhainsdehi, MP",
  "Bhalswa Jahangir Pur, DL",
  "Bhanpura, MP",
  "Bharatpur, RJ",
  "Bhatpara, WB",
  "Bhavnagar, GJ",
  "Bhilai, CG",
  "Bhilwara, RJ",
  "Bhimavaram, AP",
  "Bhiwandi, MH",
  "Bhiwani, HR",
  "Bhopal, MP",
  "Bhubaneswar, OD",
  "Bhusawal, MH",
  "Bidar, KA",
  "Bidhan Nagar, WB",
  "Bihar Sharif, BR",
  "Bijapur, KA",
  "Bikaner, RJ",
  "Bilaspur, CG",
  "Birsinghpur, MP",
  "Bokaro, JH",
  "Budhni, MP",
  "Budni, MP",
  "Bulandshahr, UP",
  "Buxar, BR",
  "Chanderi, MP",
  "Chandigarh, CH",
  "Chandrapur, MH",
  "Chapra, BR",
  "Chennai, TN",
  "Chhatarpur, MP",
  "Chhattarpur, MP",
  "Chhindwara, MP",
  "Chittoor, AP",
  "Coimbatore, TN",
  "Cuttack, OD",
  "Damoh, MP",
  "Danapur, BR",
  "Darbhanga, BR",
  "Davanagere, KA",
  "Dehradun, UK",
  "Dehri, BR",
  "Delhi, DL",
  "Deoghar, JH",
  "Dewas, MP",
  "Dhamnod, MP",
  "Dhanbad, JH",
  "Dhar, MP",
  "Dharampuri, MP",
  "Dharmavaram, AP",
  "Dhule, MH",
  "Dindigul, TN",
  "Durg, CG",
  "Durgapur, WB",
  "Eluru, AP",
  "Erode, TN",
  "Etawah, UP",
  "Faridabad, HR",
  "Farrukhabad, UP",
  "Fatehpur, UP",
  "Firozabad, UP",
  "Gadarwara, MP",
  "Gandhidham, GJ",
  "Gandhinagar, GJ",
  "Gaya, BR",
  "Ghansor, MP",
  "Ghaziabad, UP",
  "Ghughari, MP",
  "Gogawan, MP",
  "Gohad, MP",
  "Gopalpur, WB",
  "Gorakhpur, UP",
  "Gormi, MP",
  "Gotegaon, MP",
  "Gudivada, AP",
  "Gulbarga, KA",
  "Guntakal, AP",
  "Guntur, AP",
  "Gurgaon, HR",
  "Guwahati, AS",
  "Gwalior, MP",
  "Hajipur, BR",
  "Haldia, WB",
  "Hapur, UP",
  "Harda, MP",
  "Haridwar, UK",
  "Hindupur, AP",
  "Hospet, KA",
  "Howrah, WB",
  "Hubballi-Dharwad, KA",
  "Hugli and Chinsurah, WB",
  "Hyderabad, TG",
  "Ichalkaranji, MH",
  "Imphal, MN",
  "Indore, MP",
  "Jabalpur, MP",
  "Jabera, MP",
  "Jaipur, RJ","Sikar, RJ",
"Siliguri, WB",
"Singoli, MP",
"Sirsa, HR",
"Siwan, BR",
"Sohagpur, MP",
"Solapur, MH",
"Sonipat, HR",
"South Dumdum, WB",
"Sri Ganganagar, RJ",
"Srikakulam, AP",
"Srinagar, JK",
"Sultan Pur Majra, DL",
"Surat, GJ",
"Surendranagar Dudhrej, GJ",
"Suryapet, TG",
"Susner, MP",
"Tadepalligudem, AP",
"Tadipatri, AP",
"Talen, MP",
"Tarana, MP",
"Tenali, AP",
"Thane, MH",
"Thanjavur, TN",
"Thiruvananthapuram, KL",
"Thoothukudi, TN",
"Thrissur, KL",
"Tikamgarh, MP",
"Tiruchirappalli, TN",
"Tirunelveli, TN",
"Tirupati, AP",
"Tiruppur, TN",
"Tiruvottiyur, TN",
"Tumkur, KA",
"Udaipur, RJ",
"Ujjain, MP",
"Ulhasnagar, MH",
"Uluberia, WB",
"Umaria, MP",
"Unnao, UP",
"Vadodara, GJ",
"Varanasi, UP",
"Vasai-Virar, MH",
"Vellore, TN",
"Vidisha, MP",
"Vijayanagaram, AP",
"Vijayawada, AP",
"Visakhapatnam, AP",
"Warangal, TG",
"Yamunanagar, HR"];
