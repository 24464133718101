import React, { useState, useEffect } from "react";
import data from "../../src/data.json";
import sliderimage1 from "./../Assests/Homepageimages/slider1.jpg";
import sliderimage2 from "./../Assests/Homepageimages/slider2.jpg";
// import Car1 from "../Assests/Homepageimages/car1.png";
// import Car2 from "../Assests/Homepageimages/car2.png";
// import Car3 from "../Assests/Homepageimages/car3.png";
// import Car4 from "../Assests/Homepageimages/car4.png";
import mini from "../Assests/Homepageimages/minicar.jpg"
import sydan from "../Assests/Homepageimages/sydancar.jpg"
import suv from "../Assests/Homepageimages/suvcar.jpg"

import Traffic1 from "../Assests/Homepageimages/tariff-1.png";
import Traffic2 from "../Assests/Homepageimages/tariff-2.png";
import Traffic3 from "../Assests/Homepageimages/tariff-3.png";
import Traffic4 from "../Assests/Homepageimages/tariff-4.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServicestack } from "@fortawesome/free-brands-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../Assests/Homepageimages/slider1.jpg";
import slide2 from "../Assests/Homepageimages/slider1.jpg";
import slide3 from "../Assests/Homepageimages/slider1.jpg";
import slide4 from "../Assests/Homepageimages/slider1.jpg";
import slide5 from "../Assests/Homepageimages/slider1.jpg";
import './DmForm.css'
import axios from 'axios';
import { GoogleMap, LoadScript, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { citylist, servieUrl } from "../env/env";
function DmForm() {
  const homeSlideSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
  };


  const [selectedTime, setSelectedTime] = useState(null);

  // Handler function to update time selection
  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };
  useEffect(() => {
    handleSubmit111()
    // debugger
  }, [])
  const isSmallScreen = window.innerWidth <= 768;

  if (isSmallScreen) {
    homeSlideSettings.slidesToShow = 1;
  }
  const images = [sliderimage1, sliderimage2];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);
  const handleSubmit111 = async (e) => {


    try {
      // Send form data to Interakt's WhatsApp API
      await axios.post('https://api.interakt.ai/v1/public/message/', {
        fullPhoneNumber: 8400952502,
        callbackData: 'some_callback_data',
        type: 'Text',
        data: {
          message: `Name: anuj`
        }
      }, {
        headers: {
          Authorization: `Basic cE9SUXl6SEZrdTQ3cG5IRHdBSWdHa096aXNRZEVtU2oyVnJkcXF3OXJTSTo=`,
          'Content-Type': 'application/json'
        }
      });
      alert('Form data sent to WhatsApp!');
    } catch (error) {
      console.error('Error sending data to WhatsApp:', error);
    }
  };
  const [formData, setFormData] = useState({
    fromaddress: "",
    toaddress: "",
    date: "",
    time: "",
    phone: "",
    name: "",

  });

  useEffect(() => {
    const savedData = localStorage.getItem("formData");
    if (savedData) {
      setFormData(JSON.parse(savedData));
    }
  }, []);
  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  };

  const center = {
    lat: 0, // Default latitude
    lng: 0 // Default longitude
  };

  const [errors, setErrors] = useState({
    fromaddress: "",
    toaddress: "",
    date: "",
    time: "",
    phone: "",
    name: "",
    selectedCar: "",

  });

  const [showPopup, setShowPopup] = useState(false);
  const handleShowPopup = () => {
    setShowPopup(true);

    // Adjust the timeout as needed
  };

  // SelectedCar
  const [selectedCar, setSelectedCar] = useState("Mini");

  const handleCarSelect = (carType) => {
    // Log the selected car data to the console
    console.log(`Selected car: ${carType}`);

    setSelectedCar(carType);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (query) => {
    setSearchTerm(query);
    const filteredResults = data.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const handleSelect = (selectedItem) => {
    setSearchTerm(selectedItem);
    setSearchResults([]); // Clear search results after selection
  };

  const [searchTermTo, setSearchTermTo] = useState("");
  const [searchResultsTo, setSearchResultsTo] = useState([]);

  const handleSearchTo = (query) => {
    setSearchTermTo(query);
    const filteredResultsTo = data.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResultsTo(filteredResultsTo);
  };

  const handleSelectTo = (selectedItem) => {
    if (selectedItem === searchTerm) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        toaddress: "To Address cannot be the same as From Address.",
      }));
    } else {
      setSearchTermTo(selectedItem);
      setErrors((prevErrors) => ({
        ...prevErrors,
        toaddress: "",
      }));
    }
    setSearchResultsTo([]); // Clear search results after selection
  };

  // Load saved data from local storage on component mount
  useEffect(() => {
    const savedSearchTerm = localStorage.getItem("searchTerm");
    if (savedSearchTerm) {
      setSearchTerm(savedSearchTerm);
    }

    const savedSearchTermTo = localStorage.getItem("searchTermTo");
    if (savedSearchTermTo) {
      setSearchTermTo(savedSearchTermTo);
    }
  }, []); // Empty dependency array to run this effect only once on mount

  // Save input data to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("searchTerm", searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    localStorage.setItem("searchTermTo", searchTermTo);
  }, [searchTermTo]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };
const [datevalue,setDateValue]=useState("")
  const handleDateChange = (date) => {
    
    setDateValue(date)
    setFormData({
      ...formData,
      date: date, // Sets the selected date in the form data
    });
  };
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const navigate = useNavigate();
  const [bookingId, setBookingId] = useState("");
  useEffect(() => {
    setBookingId(Math.floor(100000 + Math.random() * 900000));
  }, []);

  const userData = localStorage.getItem("userData");
  const whatsappRedreicti = () => {

  }
  const handleSubmit = (e) => {
    // debugger
    e.preventDefault();

    if (localStorage.getItem("userData") === null) {
      navigate("/loginRegistration");
      return;
    }

    let isFormValid = true;

    // Validation for From Address
    if (!searchTerm.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fromaddress: "From Address is required.",
      }));
      isFormValid = false;
    }

    if (!selectedCar.trim()) {
      handleShowPopup();
      setErrors((prevErrors) => ({
        ...prevErrors,
        selectedCar: "Please Select a car.",
      }));
      isFormValid = false;
    }

    // Validation for To Address
    if (!searchTermTo.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        toaddress: "To Address is required.",
      }));
      isFormValid = false;
    }

    // Validation for Date
    if (!datevalue) {
      
      setErrors((prevErrors) => ({
        ...prevErrors,
        date: "Date is required.",
      }));
      isFormValid = false;
    }

    // Validation for Time
    if (!formData.time) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        time: "Time is required.",
      }));
      isFormValid = false;
    }

    // Validation for Phone Number

    const validPhoneNumberRegex = /^[6-9]\d{9}$/;
    if (
      !formData.phone.trim() ||
      !validPhoneNumberRegex.test(formData.phone.trim())
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Enter a Valid Number",
      }));
      isFormValid = false;
    }

    // Validation for Name
    if (!formData.name.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required.",
      }));
      isFormValid = false;
    }
    if (!datevalue  ||!selectedTime) {
      // alert('please select Date')
    }
    else{
  if (true) {

    const date1111 = new Date(selectedTime);

// Extract just the time (in 12-hour format with AM/PM)
const timeString = date1111.toTimeString().split(' ')[0];
      const currentBookingId = bookingId;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "csrftoken=tZQ0YhIzvFexGiWzliaB4MH6PoHbq2eu");

      let formData = new FormData(e.target);
      var raw = JSON.stringify({
        fromaddress: formData.get("fromaddress"),
        toaddress: formData.get("toaddress"),
        date: new Date(datevalue).toISOString().split("T")[0] ,
        time: timeString,
        phone: formData.get("phone"),
        name: formData.get("name"),
        username: userData,
        bookingId: currentBookingId,
        typeOfCar: selectedCar,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(servieUrl.url + "api/get_taxi/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          
          console.log("pring error message",result)

          const whatsappUrl = `https://api.whatsapp.com/send/?phone=+91-7440800644&text=hello`;
          // Redirect to WhatsApp
          debugger
          if (result.status){
            window.open(whatsappUrl, '_blank');
            navigate("/currentData");
          }
          else{
            alert("All Field are Mandatory  ")
          }
          
        })
        .catch((error) => console.log("error", error));

      setFormData({
        fromaddress: "",
        toaddress: "",
        date: "",
        time: "",
        phone: "",
        name: "",

      });
      setSearchTermTo("");
      setSearchTerm("");

      setErrors({
        fromaddress: "",
        toaddress: "",
        date: "",
        time: "",
        phone: "",
        name: "",

      });
    }
    }
  
  };

  const currentDate = new Date().toISOString().split("T")[0];
  const onClose = () => {
    setShowPopup(!showPopup);
  };


  const [pickupLocation, setPickupLocation] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [pickupSuggestions, setPickupSuggestions] = useState([]);
  const [dropSuggestions, setDropSuggestions] = useState([]);
  const handlePickupChange = (e) => {
    
    if(e.target.name=="fromaddress"){
     
      setFormData({
        ...formData,
        [e.target.name]:  pickupLocation,
      });
    }
    else{
      setFormData({
        ...formData,
        [e.target.name]:  e.target.vaue,
      });
    }
    
    var value = e.target.value;
    setPickupLocation(value);
    const filteredSuggestions = citylist.filter((city) =>
      city.toLowerCase().includes(value.toLowerCase())
    ).slice(0, 5);
    setPickupSuggestions(filteredSuggestions);
  };

  const handleDropChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    var value = e.target.value
    setDropLocation(value);
    const filteredSuggestions = citylist.filter((city) =>
      city.toLowerCase().includes(value.toLowerCase())
    ).slice(0, 5);
    setDropSuggestions(filteredSuggestions);
  };


  const handlePickupSelect = (value) => {
    setPickupLocation(value);
    setSearchTerm(value); // Set the search term to the selected value
    setFormData({
      ...formData,
      fromaddress: value, // Set the fromaddress in the form data
    });
    setPickupSuggestions([]); // Clear suggestions
  };

  const handleDropSelect = (value) => {
    setDropLocation(value);
    setSearchTermTo(value); // Set the search term to the selected value
    setFormData({
      ...formData,
      toaddress: value, // Set the toaddress in the form data
    });
    setDropSuggestions([]); // Clear suggestions
  };

  return (
    <>
      {showPopup && (
        <>
          <div className="fixed z-50 top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            <div className="bg-black p-5 rounded shadow-md">
              <p className="text-red-500 text-center">
                Please select a car before submitting.
              </p>
            </div>
          </div>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="" onClick={onClose}></div>
            <div className="bg-white p-5 text-xl rounded-md shadow-md">
              <p>Please select a car before submitting.</p>
              <button
                className="mt-4 px-4 py-1  bg-blue-500 text-white rounded-md"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </>
      )}

      {/* <div className="home-banner-section  w-[40rem]"> */}
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-5 pt-10 md:pt-20 px-5 md:px-10 xl:px-20 w-[100%]"> h-[30rem] overflow-y-scroll md:overflow-hidden*/}
      {/* <div className="flex justify-center items-center gap-5 pt-10 md:pt-20 px-5 md:px-10 xl:px-20 w-[100%] ml-4 -mt-[6rem]"> */}
      <div className="main-container bg-opacity-10 z-50 shadow rounded w-full h-full md:pb-4 md:pl-8 md:pt-0 pt-12 flex md:items-end md:justify-start items-center justify-center">
        <form onSubmit={handleSubmit} className="w-[90vw] sm:w-[500px] p-4 sm:px-[3rem] sm:py-[1.5rem] rounded  h-auto m-0 flex flex-col gap-2 bg-white">
          <h1 className="text-xl md:text-2xl font-bold text-gray-800">
            Book Your Taxi
            <span className="text-[#FFC61A]"> Online</span>
          </h1>
          <div className="img-container  flex items-start justify-center gap-5 w-[100%] ">
            <div
              className={`flex-1 flex flex-col justify-center p-1 rounded cursor-pointer ${selectedCar === "Mini" ? "bg-[#FFC61A]" : ""
                }`}
              onClick={() => handleCarSelect("Mini")}
            >
              <img src={mini} alt="Mini" className="form-car-resize w-full h-auto" />
              <h1 className="text-sm font-semibold text-center text-black mt-2">
                Mini  - Classic
              </h1>
            </div>
            <div
              className={`flex-1 flex flex-col justify-center p-1 rounded cursor-pointer ${selectedCar === "Syden" ? "bg-[#FFC61A]" : ""
                }`}
              onClick={() => handleCarSelect("Syden")}
            >
              <img src={sydan} alt="Syden" className="form-car-resize w-full h-auto" />
              <h1 className="text-sm font-semibold text-center text-black mt-2">
                Sydan - Premium
              </h1>
            </div>
            <div
              className={`flex-1 flex flex-col justify-center p-1 rounded cursor-pointer ${selectedCar === "SUV" ? "bg-[#FFC61A]" : ""
                }`}
              onClick={() => handleCarSelect("SUV")}
            >
              <img src={suv} alt="SUV" className="form-car-resize w-full h-auto" />
              <h1 className="text-sm font-semibold text-center text-black mt-2">
                SUV/MUV - Business class
              </h1>
            </div>
          </div>
          {errors.selectedCar && (
            <span className="text-red-400 text-sm">
              {errors.selectedCar}
            </span>
          )}
    
          <div className="flex flex-col gap-4">
            {/* <div className="input-container"> */}
            <div className="relative flex w-full gap-2">
              <div class="flex-1 relative">
                <input
                  type="text"
                  value={pickupLocation}
                  onChange={(e) => handlePickupChange(e)}
                  onBlur={(e) => setPickupSuggestions([])}
                  placeholder="Pickup Location"
                  className="text-black bg-transparent border-2 rounded !m-0 px-5 py-2 placeholder-gray-600 focus:outline-none w-full"
                  name="fromaddress"
                />
                <ul className="suggestions">
                  {pickupSuggestions.map((suggestion, index) => (
                    <li key={index} onMouseDown={() => handlePickupSelect(suggestion)}>
                      {suggestion.split(",")[0]}
                    </li>
                  ))}
                </ul>
              </div>
              <div class="flex-1 relative">
                <input
                  type="text"
                  value={dropLocation}
                  onChange={(e) => handleDropChange(e)}
                  onBlur={(e) => setDropSuggestions([])}
                  placeholder="Drop Location"
                  className="text-black bg-transparent border-2 rounded !m-0 px-5 py-2 placeholder-gray-600 focus:outline-none w-full"
                  name="toaddress"
                />
                <ul className="suggestions">
                  {dropSuggestions.map((suggestion, index) => (
                    <li key={index} onMouseDown={() => handleDropSelect(suggestion)}>
                      {suggestion.split(",")[0]}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* </div> */}
            <div className="relative flex w-full gap-2">
           
  <div className="flex-1 ">
  <label htmlFor="date">Select Date:</label>
  <div className="relative">
      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M6 2a1 1 0 011 1v1h6V3a1 1 0 112 0v1h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2h1V3a1 1 0 011-1zm1 4H5v10h10V6h-2v1a1 1 0 11-2 0V6H9v1a1 1 0 11-2 0V6z" clipRule="evenodd" />
        </svg>
      </div>
      <DatePicker
        selected={formData.date}
        onChange={handleDateChange} // Function to update formData when a date is selected
        placeholderText="DD/MM/YYYY"
        dateFormat="dd/MM/yyyy"
        className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
      />
    </div>
  </div>
  <div className="flex-1 relative">
      <label htmlFor="time-picker">Select Time:</label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M10 2a8 8 0 100 16 8 8 0 000-16zM9 4a1 1 0 112 0v4a1 1 0 01-2 0V4zm2 9a1 1 0 01-2 0v-1a1 1 0 112 0v1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <DatePicker
          selected={selectedTime} // State to control selected time
          onChange={handleTimeChange} // Update state when time changes
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15} // Time intervals (e.g., every 15 minutes)
          timeCaption="Time"
          dateFormat="h:mm aa" // Display format
          placeholderText="Select time"
          className="text-black bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
        />
      </div>
    </div>

</div>
<div className="relative flex w-full gap-2">
  <div className="flex-1 relative">
    <input
      type="tel"
      id="phone"
      name="phone"
      placeholder="Phone Number"
      value={formData.phone}
      onChange={(e) => {
        const numericValue = e.target.value.replace(/[^0-9]/g, "");
        if (numericValue !== formData.phone) {
          setFormData({
            ...formData,
            phone: numericValue,
          });
          setErrors((prevErrors) => ({
            ...prevErrors,
            phone: "",
          }));
        }
      }}
      className="text-black bg-white border-2 rounded px-5 py-2 placeholder-gray-600 focus:outline-none w-full"
      maxLength="10"
    />
  </div>
  <div className="flex-1 relative">
    <input
      type="text"
      id="name"
      name="name"
      placeholder="Name"
      value={formData.name}
      onChange={handleInputChange}
      className="text-black bg-white border-2 rounded px-5 py-2 placeholder-gray-600 focus:outline-none w-full"
    />
  </div>
</div>

           
          </div>

          <div className="flex justify-center mt-0">
            <button
              type="submit"
              className="px-5 py-2 font-bold border-2 border-[#FFC61A] text-lg rounded-md bg-blue-700 text-white w-full"
            >
              GET TAXI
            </button>
          </div>
        </form>
      </div>

      {/* </div> */}
      {/* // </div> */}
    </>

  );
}

export default DmForm;
